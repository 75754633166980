.btn:first-child:active {
  border: none !important;
}
.btn.show:target,
.btn.show:focus {
  color: inherit !important;
}
.btn-filter {
  font-size: 20px;
  color: #666;
}

.btn-filter:hover {
  color: #000;
}

.btn-filter:after {
  display: none;
}

.btn-primary {
  @include button-variant($gray, darken($gray, 7.5%), darken($gray, 10%), lighten($gray, 5%), lighten($gray, 10%), darken($gray, 30%));
  color: $white;
}

.btn-logo {
  position: relative;
  background-color: $gray-opacity;
  border-radius: 50px;
  width: 100px;
  height: 100px;
  color: $gray;
}

.btn-logo .letter {
  font-size: 30px;
}

.btn-logo .btn-file {
  background-color: $navy;
  color: $white;
}

.btn-logo .btn-file span {
  position: absolute;
  top: -6px;
  font-size: 25px;
  right: 0;
  left: 0;
  text-align: center;
}

.btn-logo .btn-file {
  height: 30px;
  width: 30px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 36px;
  cursor: pointer;
}

.btn-logo .btn-file input[type="file"] {
  font-size: 65px;
  opacity: 0;
  cursor: pointer;
  border-radius: 36px;
  margin-left: -20px;
}

.btn-logo .image-container {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 50px;
  line-height: 100px;
  text-align: center;
  overflow: hidden;
}

.btn-logo img {
  width: 100px;
  height: 100px;
  border-radius: 64px;
}
