.formset,
.header-tab {
  background-color: $white;
}
.contact-formset .formset,
.collection-formset .formset {
  border-top-right-radius: var(--bs-border-radius);
}

#forms label {
  font-weight: bold;
  color: #000000;
}

#forms h1,
#forms h2,
#forms h3,
#forms h4,
#forms h5,
#forms h6 {
  font-weight: bold;
  color: #000000;
}

#forms .text-muted {
  font-size: 12px;
}

#forms .form-control:focus {
  outline: 0;
  box-shadow: none;
}

#forms .form-width {
  width: 100%;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #forms .form-width {
    width: 100%;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  #forms .form-width {
    width: 700px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #forms .form-width {
    width: 800px;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #forms .form-width {
    width: 900px;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  #forms .form-width {
    width: 900px;
  }
}

#send-form label {
  font-weight: normal !important;
}

.modal-header {
  border-bottom: none !important;

}

.modal-footer {
 border-top: none !important;
}

.disabled {
  pointer-events: none;
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}

.disabled[type="checkbox"] {
  filter: none;
  opacity: 0.5;
}