.form-check-input {
  position: relative;
}

.form-check-input:checked {
  background-color: gray;
  border-color: gray;
}

.form-check-input:not(:checked) {
  background-color: transparent;
  border-color: gray;
}

.form-check-input:focus {
  box-shadow: none;
}