@media only screen and (max-width: 768px) {
    
    /* Force table to not be like tables anymore */
  .table-sm table,
  .table-sm thead,
  .table-sm tbody,
  .table-sm th,
  .table-sm td,
  .table-sm tr {
    display: block; 
  }
 
  /* Hide table headers (but not display: none;, for accessibility) */
  .table-sm thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .table-sm td {
    /* Behave  like a "row" */
    position: relative;
    // padding-left: 50%;
    white-space: normal;
    text-align:left;
  }

  /*
  Label the data
  */
  .table-sm td:before { content: attr(data-title); }
  .table-sm td.required:before { padding-left: 1px; content: ""; }
  .table-sm td.required:after { content: attr(data-title); }
  .table-sm > :not(caption) > * > * {
    border-bottom-width: 0;
  }
  .table-sm tr {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
  }
  .table-sm tr:last-child {
      border-bottom: none !important;
  }
}


table thead th {
  background-color: $light-blue !important;
  font-weight: normal;
}

.table-responsive {
  background-color: $white;
}

.table-last-col-fixed th:last-child,
.table-last-col-fixed td:last-child {
    width: 150px;
}