.search-filter {
  width: 100%;
}

.search-filter2 {
  width: 96%;
}

.status-filter {
  width: 100%;
}

.matter-type-filter {
  width: 100%;
}

.matter-filter {
  width: 100%;
}

.party-filter {
  width: 100%;
}

@media (min-width: 768px) {
  .search-filter {
    width: 240px;
  }

  .search-filter2 {
    width: 240px;
  }

  .status-filter {
    width: 170px;
  }

  .matter-type-filter {
    width: 230px;
  }

  .matter-filter {
    width: 230px;
  }

  .party-filter {
    width: 230px;
  }
}

.reset-filter {

}

.action-filter {
  line-height: 35px;
}