// Badge - Completed

.bg-published {
  border: 1px solid #9af1c4 !important;
  background-color: #e7fef1;
}

.dot-published {
  color: #00b461;
}

.text-published {
  color: #007842;
}

.text-verify {
  color: #ffc107;
}

.text-verify:hover {
  color: $black !important;
}

.bg-unpublished {
  border: 1px solid #ffdd7b !important;
  background-color: #fff9e9;
}

.dot-unpublished {
  color: #ff8900;
}

.text-unpublished {
  color: #c33c00;
}

.bg-complete {
  border: 1px solid #9af1c4 !important;
  background-color: #e7fef1;
}

.dot-complete {
  color: #00b461;
}

.text-complete {
  color: #007842;
}


// Badge - Import Errors
.bg-import-errors {
  border: 1px solid #ffdd7b !important;
  background-color: #fff9e9;
}

.dot-import-errors {
  color: #ff8900;
}

.text-import-errors {
  color: #c33c00;
}

// Badge - Import Checks
.bg-import-checks {
  border: 1px solid #005f89 !important;
  background-color: #d9f0f3;
}

.dot-import-checks {
  color: #136990;
}

.text-import-checks {
  color: #136990;
}

// Badge - Importing
.bg-importing {
  border: 1px solid #005f89 !important;
  background-color: #d9f0f3;
}

.dot-importing {
  color: #136990;
}

.text-importing {
  color: #136990;
}


// Badge - Collection
.bg-collection-found {
  border: 1px solid #ffdd7b !important;
  background-color: #fff9e9;
}

.dot-collection-found {
  color: #ff8900;
}

.text-collection-found {
  color: #c33c00;
}


// Badge - Collection Check
.bg-collection-check {
  border: 1px solid #005f89 !important;
  background-color: #d9f0f3;
}

.dot-collection-check {
  color: #136990;
}

.text-collection-check {
  color: #136990;
}


// Badge - Contact
.bg-contact-missing {
  border: 1px solid #ffdd7b !important;
  background-color: #fff9e9;
}

.dot-contact-missing {
  color: #ff8900;
}

.text-contact-missing {
  color: #c33c00;
}


// Badge - Contact Check
.bg-contact-check {
  border: 1px solid #005f89 !important;
  background-color: #d9f0f3;
}

.dot-contact-check {
  color: #136990;
}

.text-contact-check {
  color: #136990;
}


// Badge - Duplicates
.bg-duplicates-found {
  border: 1px solid #ffdd7b !important;
  background-color: #fff9e9;
}

.dot-duplicates-found {
  color: #ff8900;
}

.text-duplicates-found {
  color: #c33c00;
}


// Badge - Duplicate Check
.bg-duplicate-check {
  border: 1px solid #005f89 !important;
  background-color: #d9f0f3;
}

.dot-duplicate-check {
  color: #136990;
}

.text-duplicate-check {
  color: #136990;
}


// Badge - Received
.bg-received {
  border: 1px solid #9af1c4 !important;
  background-color: #e7fef1;
}

.dot-received {
  color: #00b461;
}

.text-received {
  color: #007842;
}


// Badge - Sent
.bg-sent {
  border: 1px solid #6c757d !important;
  background-color: #f8f9fa;
}

.dot-sent {
  color: #999;
}

.text-sent {
  color: #6c757d;
}


// Badge - New
.bg-new {
  border: 1px solid #6c757d !important;
  background-color: #f8f9fa;
}

.dot-new {
  color: #999;
}

.text-new {
  color: #6c757d;
}


// Badge - Invalid Matter
.bg-invalid-matter {
  border: 1px solid #c83e50 !important;
  background-color: #feb2b2;
}

.dot-invalid-matter {
  color: #ff0000;
}

.text-invalid-matter {
  color: #6e0c01;
}


// Badge - Error
.bg-error {
  border: 1px solid #c83e50 !important;
  background-color: #feb2b2;
}

.dot-error {
  color: #ff0000;
}

.text-error {
  color: #6e0c01;
}