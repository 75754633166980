* {
  // font-family: 'adobe_caslon';
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'open-sans', Helvetica, Sans-Serif;
  color: $navy;
}

a {
  text-decoration: none;
  color: $navy;
}

input,
select,
textarea {
  box-shadow: none !important;
}

input:focus,
select:focus,
textarea:focus {
  border: 1px solid $navy !important;
}

input:active,
select:active,
textarea:active {
  border: 1px solid $navy !important;
}

table thead tr {
  line-height: 100%;
}

table tbody tr:last-child td {
  border: none !important;
}

.header-bar-top {
  left: 80px;
  min-height: 62px;
  border-bottom: 1px solid $gray;
  background-color: $white;
}

.alert-bar-top {
  left: 80px;
  min-height: 62px;
}

.content-container {
  padding-top: 90px !important;
  background-color: #f9f9f9;
}