@font-face {
    font-family: 'batangasbold';
    src: url('../fonts/batangas/batangas_bold_700-webfont.woff2') format('woff2'),
         url('../fonts/batangas/batangas_bold_700-webfont.woff') format('woff'),
         url('../fonts/batangas/batangas_bold_700-webfont.ttf') format('truetype'),
         url('../fonts/batangas/batangas_bold_700-webfont.svg#batangasbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'franklin-gothic-book';
    src: url('../fonts/franklin-gothic-book/franklin-gothic-book-webfont.woff2') format('woff2'),
         url('../fonts/franklin-gothic-book/franklin-gothic-book-webfont.woff') format('woff'),
         url('../fonts/franklin-gothic-book/franklin-gothic-book-webfont.ttf') format('truetype'),
         url('../fonts/franklin-gothic-book/franklin-gothic-book-webfont.svg#batangasbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'adobe_caslon';
    src: url('../fonts/acaslonpro/acaslonpro-bold-webfont.woff2') format('woff2'),
         url('../fonts/acaslonpro/acaslonpro-bold-webfont.woff') format('woff'),
         url('../fonts/acaslonpro/acaslonpro-bold-webfont.ttf') format('truetype'),
         url('../fonts/acaslonpro/acaslonpro-bold-webfont.svg#adobe_caslon_probold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'adobe_caslon';
    src: url('../fonts/acaslonpro/acaslonpro-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/acaslonpro/acaslonpro-bolditalic-webfont.woff') format('woff'),
         url('../fonts/acaslonpro/acaslonpro-bolditalic-webfont.ttf') format('truetype'),
         url('../fonts/acaslonpro/acaslonpro-bolditalic-webfont.svg#adobe_caslon_probold_italic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'adobe_caslon';
    src: url('../fonts/acaslonpro/acaslonpro-italic-webfont.woff2') format('woff2'),
         url('../fonts/acaslonpro/acaslonpro-italic-webfont.woff') format('woff'),
         url('../fonts/acaslonpro/acaslonpro-italic-webfont.ttf') format('truetype'),
         url('../fonts/acaslonpro/acaslonpro-italic-webfont.svg#adobe_caslon_proitalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'adobe_caslon';
    src: url('../fonts/acaslonpro/acaslonpro-regular-webfont.woff2') format('woff2'),
         url('../fonts/acaslonpro/acaslonpro-regular-webfont.woff') format('woff'),
         url('../fonts/acaslonpro/acaslonpro-regular-webfont.ttf') format('truetype'),
         url('../fonts/acaslonpro/acaslonpro-regular-webfont.svg#adobe_caslon_proregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'adobe_caslon';
    src: url('../fonts/acaslonpro/acaslonpro-semibold-webfont.woff2') format('woff2'),
         url('../fonts/acaslonpro/acaslonpro-semibold-webfont.woff') format('woff'),
         url('../fonts/acaslonpro/acaslonpro-semibold-webfont.ttf') format('truetype'),
         url('../fonts/acaslonpro/acaslonpro-semibold-webfont.svg#adobe_caslon_prosemibold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'adobe_caslon';
    src: url('../fonts/acaslonpro/acaslonpro-semibolditalic-webfont.woff2') format('woff2'),
         url('../fonts/acaslonpro/acaslonpro-semibolditalic-webfont.woff') format('woff'),
         url('../fonts/acaslonpro/acaslonpro-semibolditalic-webfont.ttf') format('truetype'),
         url('../fonts/acaslonpro/acaslonpro-semibolditalic-webfont.svg#adobe_caslon_proSBdIt') format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'montserrat';
    src: url('../fonts/montserrat/montserrat-regular-webfont.woff2') format('woff2'),
         url('../fonts/montserrat/montserrat-regular-webfont.woff') format('woff'),
         url('../fonts/montserrat/montserrat-regular-webfont.ttf') format('truetype'),
         url('../fonts/montserrat/montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: "open-sans";
    src: url("../fonts/open-sans/opensans-bold-webfont.woff2") format("woff2"),
         url("../fonts/open-sans/opensans-bold-webfont.woff") format("woff"),
         url("../fonts/open-sans/opensans-bold-webfont.ttf") format("truetype"),
         url("../fonts/open-sans/opensans-bold-webfont.svg") format("svg");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "open-sans";
    src: url("../fonts/open-sans/opensans-bolditalic-webfont.woff2") format("woff2"),
         url("../fonts/open-sans/opensans-bolditalic-webfont.woff") format("woff"),
         url("../fonts/open-sans/opensans-bolditalic-webfont.ttf") format("truetype"),
         url("../fonts/open-sans/opensans-bolditalic-webfont.svg") format("svg");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "open-sans";
    src: url("../fonts/open-sans/opensans-extrabold-webfont.woff2") format("woff2"),
         url("../fonts/open-sans/opensans-extrabold-webfont.woff") format("woff"),
         url("../fonts/open-sans/opensans-extrabold-webfont.ttf") format("truetype"),
         url("../fonts/open-sans/opensans-extrabold-webfont.svg") format("svg");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "open-sans";
    src: url("../fonts/open-sans/opensans-extrabolditalic-webfont.woff2") format("woff2"),
         url("../fonts/open-sans/opensans-extrabolditalic-webfont.woff") format("woff"),
         url("../fonts/open-sans/opensans-extrabolditalic-webfont.ttf") format("truetype"),
         url("../fonts/open-sans/opensans-extrabolditalic-webfont.svg") format("svg");
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: "open-sans";
    src: url("../fonts/open-sans/opensans-italic-webfont.woff2") format("woff2"),
         url("../fonts/open-sans/opensans-italic-webfont.woff") format("woff"),
         url("../fonts/open-sans/opensans-italic-webfont.ttf") format("truetype"),
         url("../fonts/open-sans/opensans-italic-webfont.svg") format("svg");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "open-sans";
    src: url("../fonts/open-sans/opensans-light-webfont.woff2") format("woff2"),
         url("../fonts/open-sans/opensans-light-webfont.woff") format("woff"),
         url("../fonts/open-sans/opensans-light-webfont.ttf") format("truetype"),
         url("../fonts/open-sans/opensans-light-webfont.svg") format("svg");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "open-sans";
    src: url("../fonts/open-sans/opensans-lightitalic-webfont.woff2") format("woff2"),
         url("../fonts/open-sans/opensans-lightitalic-webfont.woff") format("woff"),
         url("../fonts/open-sans/opensans-lightitalic-webfont.ttf") format("truetype"),
         url("../fonts/open-sans/opensans-lightitalic-webfont.svg") format("svg");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "open-sans";
    src: url("../fonts/open-sans/opensans-regular-webfont.woff2") format("woff2"),
         url("../fonts/open-sans/opensans-regular-webfont.woff") format("woff"),
         url("../fonts/open-sans/opensans-regular-webfont.ttf") format("truetype"),
         url("../fonts/open-sans/opensans-regular-webfont.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "open-sans";
    src: url("../fonts/open-sans/opensans-semibold-webfont.woff2") format("woff2"),
         url("../fonts/open-sans/opensans-semibold-webfont.woff") format("woff"),
         url("../fonts/open-sans/opensans-semibold-webfont.ttf") format("truetype"),
         url("../fonts/open-sans/opensans-semibold-webfont.svg") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "open-sans";
    src: url("../fonts/open-sans/opensans-semibolditalic-webfont.woff2") format("woff2"),
         url("../fonts/open-sans/opensans-semibolditalic-webfont.woff") format("woff"),
         url("../fonts/open-sans/opensans-semibolditalic-webfont.ttf") format("truetype"),
         url("../fonts/open-sans/opensans-semibolditalic-webfont.svg") format("svg");
    font-weight: 400;
    font-style: italic;
}
