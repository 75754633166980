#sidebar {
  background-color: $navy;
  color: $white;
}
#sidebar a:hover,
#sidebar a {
  color: $white;
}

.bootstrap-select > .dropdown-toggle,
.bootstrap-select > .dropdown-toggle:hover {
  background-color: $white;
  color: $navy;
  border: none;
}

.bootstrap-select > .dropdown-toggle:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
}