.white-bg {
  background-color: $white;
}
.text-ellipsis {
  text-overflow: text-ellipsis;
}

.bi-file-earmark-text {
  background-color: $file-icon;
}

#submissions tbody tr:hover,
#sent tbody tr:hover {
  cursor: pointer;
}

.formset-row .header-tab,
.handle-bars,
.handle {
  cursor: pointer !important;
}

.is-multi-row-field {
  float: left;
  margin-right: 10px;
}

.menu-item.selected {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.formset-selected .formset,
.formset-selected .header-tab {
  background-color: $white;
}

.modal-header h5 {
  color: $navy;
}

.form-block {
  background-color: $white;
}
.form-block .inner-content {
  width: 100%;
}

@media (max-width: 767px) {
  .form-block {
    position: relative;
  }

  .form-block .remove {
    position: absolute;
    top: 0;
    right: 0;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .form-block .inner-content {
    width: 50%;
  }
}

.file-name {
    line-height: 35px;
    position: relative;
    width: 100%;
}

.file-name:before {
    content: ' ';
    visibility: hidden;
}

.file-name span {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file-action {
  line-height: 35px;
  width: 60px;
}

.file-action i {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.bootstrap-select .dropdown-menu.show {
  width: 100%;
}

.duplicate-container {
  margin: 0 auto;
  width: 450px;
  background-color: $white;
}

.white-bg {
  background-color: $white;
}

.contact-type-name {
  height: 38px;
}


// Hides the dropdown carrot
.dropdown-toggle:after { content: none }

.dropdown a {
  font-family: var(--bs-body-font-family);
  color: $black !important;
}

.dropdown-menu {
  cursor: pointer;
}

.dropdown-item:hover {
  color: $white !important;
  background-color: $action !important;
}

.dropdown-item.active {
  color: $white !important;
  background-color: $action;
}