.dropzone {
    position: relative;
    border: 2px dashed #ccc;
    cursor: pointer;
    border-radius: 6px;
}
.dropzone.active,
.dropzone:hover {
  border: 2px dashed #333;
}
.file-input {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  cursor: pointer;
}