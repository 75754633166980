.icon {
  text-indent: 100%;
  font-size: 10px;
  background: url('../images/sidebar/sprites.svg') no-repeat;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  width: 48px;
  height: 48px;
  border-radius: 8px;

}

.icon-dashboard {
  background-position: 8px -30px;
}

.icon-forms {
  background-position: 8px -70px;
}

.icon-web-form {
  background-position: 8px -110px;
}

.icon-matters {
  background-position: 8px -150px;
}

.icon-submissions {
  background-position: 8px -188px;
}

.icon-settings {
  background-position: 8px -228px;
}


.active .icon-dashboard,
.icon-dashboard:hover {
  background-position: -32px -30px;
  background-color: $gray-alt;
}

.active .icon-forms,
.icon-forms:hover {
  background-position: -32px -70px;
  background-color: $gray-alt;
}

.active .icon-web-form,
.icon-web-form:hover {
  background-position: -32px -110px;
  background-color: $gray-alt;
}

.active .icon-matters,
.icon-matters:hover {
  background-position: -32px -150px;
  background-color: $gray-alt;
}


.active .icon-submissions,
.icon-submissions:hover {
  background-position: -32px -188px;
  background-color: $gray-alt;
}

.active .icon-settings,
.icon-settings:hover {
  background-position: -32px -228px;
  background-color: $gray-alt;
}


.nav-pills .nav-link.active,
.nav-pills .show > .nav-link,
.nav-link.active {
  background-color: transparent;
  color: $navy;
}

.bi-copy {
  cursor: pointer !important;
}